import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';
import About from '@sections/About';
import Footer from '@sections/Footer';
import Posts from '@sections/Posts';
import Contact from '@sections/Contact';

const IndexPage = ({ data }) => {
  const {
    allMdx: { edges },
  } = data;
  const posts = edges.map((e) => e.node);

  return (
    <Layout>
      <Navbar />
      <Header />
      <About />
      <Posts posts={posts} accent />
      <Contact />
      <Footer />
    </Layout>
  );
};

// TODO: filter this by featured field
export const query = graphql`
  {
    allMdx(
      filter: {
        slug: { regex: "/writing/i" }
        frontmatter: { featured: { eq: true }, draft: { ne: true } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 4
    ) {
      edges {
        node {
          frontmatter {
            slug
            date(formatString: "MMMM YYYY")
            title
            excerpt
            tag
          }
          fields {
            coverImageRelative {
              childImageSharp {
                gatsbyImageData(width: 960, layout: CONSTRAINED)
              }
              name
            }
          }
          timeToRead
        }
      }
    }
  }
`;

export default IndexPage;
