import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Typewriter from 'typewriter-effect';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { Container } from '@components/global';
// import ExternalLink from '@common/ExternalLink';
import RandomGenerator from '@util/RandomGenerator';
import { AuthorLabels } from '@config';

const headerImagesQuery = graphql`
  {
    headerImages: allFile(filter: { sourceInstanceName: { eq: "header" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
          }
          name
        }
      }
    }
  }
`;

const Header = () => {
  const data = useStaticQuery(headerImagesQuery);
  const { headerImages } = data;
  const { edges } = headerImages;
  const randomPosition = RandomGenerator(0, edges.length - 1);
  const randomHeaderImage = edges[randomPosition].node;
  return (
    <HeaderWrapper>
      <Container>
        <Grid>
          <Art>
            <GatsbyImage
              image={randomHeaderImage.childImageSharp.gatsbyImageData}
              alt={randomHeaderImage.name}
            />
          </Art>
          <Text>
            <h1>
              <Typewriter
                onInit={(typewriter) => {
                  AuthorLabels.forEach((label, i, labels) => {
                    typewriter.typeString(label).pauseFor(1500);
                    if (i !== labels.length - 1) typewriter.deleteAll();
                  });
                  typewriter.start();
                }}
                options={{
                  cursor: '',
                  delay: 70,
                }}
              />
            </h1>
            <br />
            <p>
              <StyledAnchorLink href={'#about'}>
                Want to know more? ↯
              </StyledAnchorLink>
            </p>
          </Text>
        </Grid>
      </Container>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  background-color: ${(props) => props.theme.color.primary};
  padding-top: 96px;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    padding-top: 128px;
  }
`;

const Art = styled.figure`
  width: 100%;
  margin: 0;
  filter: invert(${(props) => (props.theme.invertImages ? 1 : 0)});
  transition: filter 0.2s ease-out;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      width: 100%;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 64px;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 80px;

    > ${Art} {
      order: 2;
    }
  }
`;

const Text = styled.div`
  // justify-self: center;
  width: 100%;
  text-align: right;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: start;
    text-align: inherit;
  }
`;

const StyledAnchorLink = styled(AnchorLink)`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.color.font.regular};
  }
`;

export default Header;
