import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Section, Container } from '@components/global';
import { commonHeadingStyles } from '@article/MDX/PreviewComponents';
import MDXRenderer from '@article/Mdx';
import RandomGenerator from '@util/RandomGenerator';

const About = () => (
  <StaticQuery
    query={graphql`
      {
        about: mdx(frontmatter: { slug: { eq: "about" } }) {
          body
          frontmatter {
            title
          }
          timeToRead
        }
        aboutImages: allFile(filter: { sourceInstanceName: { eq: "art" } }) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
              }
              name
            }
          }
        }
      }
    `}
    render={(data) => {
      const { aboutImages, about } = data;
      const { edges } = aboutImages;
      const randomPosition = RandomGenerator(0, edges.length - 1);
      const randomAboutImage = edges[randomPosition].node;
      return (
        <Section id="about">
          <Container style={{ position: 'relative' }}>
            <AboutBlock>
              <h1>{about.frontmatter.title}</h1>
              <MDXRenderer content={about.body} />
            </AboutBlock>
            <Art>
              <GatsbyImage
                image={randomAboutImage.childImageSharp.gatsbyImageData}
                alt={randomAboutImage.name}
              />
            </Art>
            <ArtMobile>
              <GatsbyImage
                image={randomAboutImage.childImageSharp.gatsbyImageData}
                alt={randomAboutImage.name}
              />
            </ArtMobile>
          </Container>
        </Section>
      );
    }}
  />
);

const AboutBlock = styled.div`
  display: block;
  width: 60%;
  min-height: 600px;
  margin-top: 72px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${commonHeadingStyles}
  }

  @media (max-width: ${(props) => props.theme.screen.lg}) {
    justify-content: start;
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 100%;
    min-height: auto;
    grid-template-columns: auto;
  }

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    grid-gap: 24px;
  }
`;

const Art = styled.figure`
  width: 800px;
  margin: -80px 0;
  position: absolute;
  top: 0;
  left: 70%;
  filter: invert(${(props) => (props.theme.invertImages ? 1 : 0)});
  transition: filter 0.2s ease-out;

  @media (max-width: ${(props) => props.theme.screen.lg}) {
    top: 20%;
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    display: none;
  }
`;

const ArtMobile = styled.figure`
  width: 100%;
  margin: 0;
  display: none;
  margin-top: 64px;
  margin-bottom: -140px;
  filter: invert(${(props) => (props.theme.invertImages ? 1 : 0)});
  transition: filter 0.2s ease-out;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    display: block;
  }
`;

export default About;
